import { Button, Grid, Typography, FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getApiDomain } from "../../../App";
import { useGiftCard } from "../../../context/GiftCards";
import { saveAs } from 'file-saver';
import Excel from "exceljs"
import {
    Route,
    Routes,
    useNavigate
  } from "react-router-dom";
import EmployeeTotalPage from "./EmployeeTotalPage";
import moment from "moment-timezone";
const url = getApiDomain();
const listOfMonths = [
    {
        title: "All",
        startDate: 1730869200,
        endDate: 1735621200
    },
    {
        title: "October",
        startDate: 1727755200,
        endDate: 1730347200
    },
    {
        title: "November",
        startDate: 1730433600,
        endDate: 1732942800
    },
    {
        title: "December",
        startDate: 1733029200,
        endDate: 1735621200
    },
    {
        title: "HFOT",
        startDate: 1719374400,
        endDate: 1723348800
    },
]
export default function EmployeeOverviewByDate(props) {
    const [employees, setServers] = useState({});
    const [activeMonth, setActiveMonth] = useState(listOfMonths[0])
    const [loading, setLoading] = useState(true);
    const [giftCardTotals, setGiftCardTotals] = useState();
    const [selectedMonth, setSelectedMonth] = useState(0)
    const {loadGiftCardTotals} = useGiftCard();
    const handleChange = (event) => {
        setSelectedMonth(event.target.value);
        setActiveMonth(listOfMonths[event.target.value])
      };
    const nav = useNavigate();
    const exportServerTracker = async () => {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("Gift Card Totals");
        let outsideBorderThickness = "medium";
        let row = 1;
        worksheet.getCell('A' + row).value = "Gift Card Teams Totals";
        worksheet.getCell('A' + row).style ={
            font: {
                bold: true,
                size: 18
            }
        }
        worksheet.mergeCells('A' + row + ':D'+row)
        worksheet.getCell('A' + row).alignment = {horizontal: 'center'}
        row++;
        worksheet.getCell('A' + row).value = "Updated - " + moment().format("MM/DD/YYYY");
        worksheet.getCell('A' + row).style ={
            font: {
                bold: true,
                size: 12
            }
        }
        worksheet.mergeCells('A' + row + ':D'+row)
        worksheet.getCell('A' + row).alignment = {horizontal: 'center'}
        row++;
        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 15;
        worksheet.getColumn(3).width = 15;
        worksheet.getColumn(4).width = 15;
        Object.keys(employees).map((key, index)=>{
            const employee = employees[key];
            worksheet.getCell('A' + row).value = employee.name
            worksheet.getCell('B' + row).value = selectedMonth=== 4 ? giftCardTotals[employee.id] ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(giftCardTotals[employee.id].round_ups)  : "$0.00" : giftCardTotals[employee.id] ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(giftCardTotals[employee.id].gift_cards + giftCardTotals[employee.id].round_ups) : "$0.00"
            worksheet.getCell('C' + row).value = giftCardTotals[employee.id] ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(giftCardTotals[employee.id].gift_cards) : "$0.00";
            worksheet.getCell('D' + row).value = giftCardTotals[employee.id] ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(giftCardTotals[employee.id].round_ups) : "$0.00"
            worksheet.getCell('A' + (row)).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb: index % 2 === 0 ? 'd3d3d3' : 'ffffff'},
            };
            worksheet.getCell('B' + (row)).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb: index % 2 === 0 ? 'd3d3d3' : 'ffffff'},
            };
            worksheet.getCell('C' + (row)).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb: index % 2 === 0 ? 'd3d3d3' : 'ffffff'},
            };
            worksheet.getCell('D' + (row)).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb: index % 2 === 0 ? 'd3d3d3' : 'ffffff'},
            };
            row++;
        });
        worksheet.pageSetup.printArea="A1:F" + (row + 1);
        worksheet.pageSetup.fitToPage = true;
        const buf = await workbook.xlsx.writeBuffer()
        saveAs(new Blob([buf]), 'Gift Card Totals - '+moment().format("MM-DD-YYYY")+'.xlsx')

    }
    const loadingGiftCardData = () => {
        fetch(url + "/giftcards/stats/foodrunning/date/"+activeMonth.startDate+"/" + activeMonth.endDate).then((res)=>{
            setGiftCardTotals({...res.data.data.foodrunning})
            console.log(res.data.data.foodrunning)
            console.log(activeMonth.startDate)
            setLoading(false);
        })
    }
    const loadingAllGiftCardData = () => {
        axios.get(url + "/giftcards/stats/foodrunning/all").then((res)=>{
            setGiftCardTotals({...res.data.data.foodrunning})
            console.log(res.data.data.foodrunning)
            setLoading(false);
        })
    }
    useEffect(()=>{
        axios.get(url + "/employeesbyschedule/" + "641344900").then((res)=>{
            console.log(res.data.data);
            if(!res.data.data.employees){
                return;
            }
            setServers(Array.from(res.data.data.employees))
        })
    }, []);
    useEffect(()=>{
        if(selectedMonth === 0){
            console.log("All")
            loadGiftCardTotals().then((totals)=>{
                Object.keys(totals).forEach((key)=>{
                    totals[key].round_ups = totals[key].round_up_total
                    totals[key].gift_cards = totals[key].gift_cards_total

                })
                console.log(totals)

                setGiftCardTotals({...totals})
                setLoading(false);
            })
        } else {
            loadingGiftCardData()
        }
        
    }, [selectedMonth   ]);
    return(
        <React.Fragment>
            {!loading ? 
        <React.Fragment>
        <Typography
            variant="h3"
        >
            Server Overview Totals            
            <Button
                onClick={()=>{
                    exportServerTracker();
                }}
            >
                Export
            </Button>
        </Typography>
        <Typography
            variant="h5"
        >
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="date">Month</InputLabel>
            <Select
                labelId="date"
                id="month"
                value={selectedMonth}
                label="Month"
                onChange={handleChange}
            >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>October</MenuItem>
                <MenuItem value={2}>November</MenuItem>
                <MenuItem value={3}>December</MenuItem>
                <MenuItem value={4}>HFOT</MenuItem>

            </Select>
        </FormControl>
            {moment.unix(activeMonth.startDate).format("MM-DD-YYYY") + " - " + moment.unix(activeMonth.endDate).format("MM-DD-YYYY")}            
        </Typography>
        <Grid padding={2} container>
            <Grid
                item
                xs={3}
            >
                Name
            </Grid>
            <Grid
                item
                xs={3}
            >
                Total
            </Grid>
            <Grid
                item
                xs={3}
            >
                Gift Cards
            </Grid>
            <Grid
                item
                xs={3}
            >
                Round Ups
            </Grid>
            {
                Object.keys(employees).map((key, index)=>{
                    const employee = employees[key];
                    if(employee.active === 1){
                    try{
                        return(
                            <Grid key={employee.id} item xs={12} sx={{background: (index % 2 === 0) ? "#E8E8E8" : "#FFFFFF"}}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Button
                                            sx={{color: "black"}}
                                            onClick={()=>{
                                                nav("../" + employee.id)
                                            }}
                                        >{employee.name}</Button>
                                    </Grid>
                                    <Grid item xs={3}
                                        sx={{
                                            verticalAlign: "middle"
                                        }}
                                    >
                                        {selectedMonth=== 4 ? giftCardTotals[employee.id] ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(giftCardTotals[employee.id].round_ups)  : "$0.00" :
                                        giftCardTotals[employee.id] ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(giftCardTotals[employee.id].gift_cards + giftCardTotals[employee.id].round_ups) : "$0.00"}
                                    </Grid>
                                    <Grid item xs={3}
                                            sx={{
                                            verticalAlign: "middle"
                                        }}
                                    >
                                        {giftCardTotals[employee.id] ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(giftCardTotals[employee.id].gift_cards) : "$0.00"}
                                    </Grid>
                                    <Grid item xs={3}
                                        sx={{
                                            verticalAlign: "middle"
                                        }}
                                    >
                                        {giftCardTotals[employee.id] ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(giftCardTotals[employee.id].round_ups) : "$0.00"}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    } catch (err) {
                        console.log(err)
                        return(
                            <React.Fragment>
                                <Grid xs={3}>
                                    {employee.name}
                                </Grid>
                                <Grid xs={9}>
                                    Missing sales data
                                </Grid>
                            </React.Fragment>
                        )
                    }
                }

                })
            }
        </Grid>
    </React.Fragment>
            : ""}
        </React.Fragment>
    )
}

